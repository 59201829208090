import React from "react";

import Bilprovningen from "../images/CustomerLogos/Bilprovningen.png"
import Certas from "../images/CustomerLogos/Certas.png"
import CK from "../images/CustomerLogos/CK.png"
import DeKa from "../images/CustomerLogos/DeKa.png"
import IONITY from "../images/CustomerLogos/IONITY.png"
import Istobal from "../images/customerLogos/istobal.png"
import Max from "../images/CustomerLogos/max.png"
import OKQ8 from "../images/CustomerLogos/okq8.png"
import Preem from "../images/CustomerLogos/Preem.png"
import St1 from "../images/CustomerLogos/st1.png"
import Qstar from "../images/CustomerLogos/Qstar.png"


export default () => (
  <div className="w-full mb-12 ">
    <h1 className="w-full my-6 text-1xl md:text-2xl font-bold leading-tight text-center text-grey-800">
      Några av våra samarbetspartners
    </h1>
    <div className="flex flex- flex-col flex-grow  flex-shrink flex-wrap items-center
                    md:flex-row md:justify-center md:mx-40">

      {/* alfabetisk ordning */}
      <img
        src={Bilprovningen}
        alt="Bilprovningen"
        title="Bilprovningen"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={Certas}
        alt="Certas"
        title="Certas"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={CK}
        alt="Cirkle K"
        title="Cirkle K"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={DeKa}
        alt="DeKa"
        title="DeKa"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={IONITY}
        alt="Ionity"
        title="Ionity"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={Istobal}
        alt="Istobal"
        title="Istobal"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>

      <img
        src={Max}
        alt="Max hamburgerestaurang"
        title="Max hamburgerestaurang"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={OKQ8}
        alt="OkQ8"
        title="OkQ8"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={Preem}
        alt="Preem"
        title="Preem"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={St1}
        alt="St1"
        title="St1"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
      <img
        src={Qstar}
        alt="Qstar"
        title="Qstar"
        className="mb-3 mx-6 w-auto h-20"
      >
      </img>
    </div>
  </div>
);


