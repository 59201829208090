import React from "react";
import AboutSam from "./AboutSam.js";
import AboutDrawings from "./AboutDrawings.js";
import AboutFieldwork from "./AboutFieldwork.js";
import AboutProjectPlanning from "./AboutProjectPlanning.js";
import AboutErrorReport from "./AboutErrorReport.js";
import AboutAuthorities from "./AboutAuthorities.js";

export default () => (
  <div>
    <section className="bg-white py-8">
      <div className="container mx-auto flex flex-wrap pt-4 pb-12">
        <div className="w-full mb-4"></div>
        <AboutSam />
        <AboutDrawings />
        <AboutFieldwork />
        <AboutProjectPlanning />
        <AboutErrorReport />
        <AboutAuthorities />
      </div>
    </section>
  </div>
);
