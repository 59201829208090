import React from "react";
import { Link, navigate } from "gatsby";

export default () => (
  <div>
    <section
      className="container mx-auto text-center py-4 mt-4">
      <h1 className="w-full my-6 text-1xl lg:text-2xl font-bold leading-tight text-center text-black">
        Kom och jobba med oss!
      </h1>
      <div className="w-full">
        <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
        <button
          type="button"
          className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 p-4 px-8 shadow-lg"
          onClick={() => navigate('/career')}
        >
          Ansök
        </button>
    </section>
  </div>
);
