import React from "react";
import NavBar from "./NavBar.js";
import HeaderHomeSVG from "../../images/svgFiles/HeaderSVG.js";
import FrontpageHeaderImage from "../../images/inHouseImages/frontpageHeaderImage.png";


export default ({ data, children }) => (
  <header className="leading-normal tracking-normal text-white gradient">
    <NavBar />
    {children}
    <div className="pb-0">
    
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-left md:text-left">
          <h1 className="my-4 text-5xl font-bold leading-tight">
            {" "}
            {data.site.siteMetadata.title}
          </h1>
          {/*<p className="uppercase tracking-loose w-full text-2xl">site management made easier</p>*/}
          <p className="leading-normal text-lg md:text-xl mb-8">
            {" "}
            Er totalleverantör inom anläggningshantering.
          </p>
          {/* 
          <p className="leading-normal text-2xl mb-8">
            {" "}
            Vi erbjuder konsult- och projektledartjänster där vi hjälper
            kunderna med allt från utredningar och felanmälningar till totala
            uppritningar med bl.a. yttre VA och alla nödvändiga
            myndighetskontakter för bygglov. <br></br>{" "}
          </p>
          */}
        </div>

        <div className="w-full md:w-3/5 py-6 text-center">
          {/* hearoImg */}
          <img className="w-full md:w-full z-50 md:pl-32" src={FrontpageHeaderImage} />{" "}
        </div>
      </div>
    </div>
    {/* Waves SVG */}
    <div className="relative -mt-12 lg:-mt-24">
      <HeaderHomeSVG />
    </div>
  </header>
);
