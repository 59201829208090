import React from "react";
import { Link } from "gatsby";

export default () => (
  <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full font-bold text-xl text-gray-800 px-6 pt-6">
        Fältarbete | 360º
      </div>
      <p className="text-gray-800 text-base px-6">
        Vi utför inmätningar och fotograferingar i fält som grund för ritningar
        eller som kompletterande underlag skräddarsydda för era behov.
      </p>
    </div>
    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-1">
      <div className="flex items-center justify-center">
        <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-2 py-2 px-6 shadow">
          <Link to="/productsAndServices/fieldwork">Läs mer</Link>
        </button>
      </div>
    </div>
  </div>
);
