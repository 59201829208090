import React from "react";
import Layout from "../components/layout/Layout.js";
import SEO from "../components/SEO.js";
import HeaderHome from "../components/layout/HeaderHome.js";
import SiteTitleQuery from "../components/SiteTitleQuery.js";
import Title from "../components/layout/Title.js";
// import QuoteCards from "../components/Quotes/QuoteCards.js";
import CallToAction from "../components/CallToAction.js";
import AboutCardsAll from "../components/aboutCards/AboutCardsAll.js";
import Customers from "../components/Customers.js";


function HomePage() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title=" "
            keywords={[`lorem`, `ipsum`, `dolor`, `sit`, `amet`]}
          />
          <HeaderHome data={data} />
          <Title title="Produkter och Tjänster" />
          <AboutCardsAll />
          {/* <QuoteCards /> */}
          <Customers />
          <CallToAction />
        </Layout>
      )}
    />
  );
}

export default HomePage;
